'use client';

import {
    Flex,
    Heading,
    Text,
    Button,
    Image,
    Box,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { useSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@app/routes';

export default function Page() {
    return (
        <>
            <Flex as="header" p={4} justify="end" maxW="7xl" mx="auto">
                <SignInButton text="Login" />
            </Flex>
            <Flex
                as="main"
                direction="column"
                align="center"
                justify="center"
                textAlign="center"
                gap={12}
                px={8}
                py={24}
            >
                <Heading as="h1" fontSize="3xl" fontWeight="extrabold">
                    ConnectAI
                </Heading>

                <Text fontSize="lg" opacity="0.8">
                    Coming Soon
                </Text>
            </Flex>
        </>
    );
}

function SignInButton({
    text = 'Get started',
    extraStyle,
}: {
    text?: string;
    extraStyle?: React.CSSProperties;
}) {
    const router = useRouter();
    const { data: session, status } = useSession();

    const handleClick = () => {
        if (status === 'authenticated') {
            router.push(ROUTES.DASHBOARD);
        } else {
            signIn(undefined, { callbackUrl: ROUTES.DASHBOARD });
        }
    };

    if (status === 'authenticated') {
        return (
            <ChakraLink href={ROUTES.DASHBOARD} style={extraStyle}>
                {session.user?.image ? (
                    <Image
                        src={session.user?.image}
                        alt={session.user?.name || 'Account'}
                        borderRadius="full"
                        boxSize="6"
                        mr={2}
                    />
                ) : (
                    <Box
                        w="6"
                        h="6"
                        bg="base.300"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="full"
                        mr={2}
                    >
                        {session.user?.name?.charAt(0) ||
                            session.user?.email?.charAt(0)}
                    </Box>
                )}
                {session.user?.name || session.user?.email || 'Account'}
            </ChakraLink>
        );
    }

    return (
        <Button style={extraStyle} onClick={handleClick}>
            {text}
        </Button>
    );
}
